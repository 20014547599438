.navbar {
  position: fixed;
  z-index: 3000 !important;
  height: 80px;
  overflow: hidden;
  padding-left: 35px !important;
}
.navbar-light {
  background: rgb(0, 0, 0, 0.3) !important;

  box-shadow: 1px 1px 2px 0px rgba(59, 59, 59, 0.75);
}
.scrolled {
  background: rgb(0, 0, 0) !important;
}
.nav__link {
  display: flex;
  align-items: center;
}
.nav__link:hover{
  border-bottom: 1px solid rgb(255, 254, 253);
}
.nav__link img {
  width: 20px;
  height: 20px;
  margin-right: 2px;
  color: #abb7c4 !important;
  /* filter: invert(100%) sepia(57%) saturate(1318%) hue-rotate(175deg)
    brightness(100%) contrast(84%); */
}

.navbar-light ul {
  margin-left: 20px;
}
.navbar-light ul .nav-item {
}
.navbar-light a {
  color: #d6e0eb !important;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
}
.first__form {
  position: relative;
}
.first__btn {
  position: absolute;
  right: 0;
}
.first__form input {
  background-color: transparent !important;
  height: 42px;
  width: 250px !important;
  border-radius: 30px;
  border: 1px solid rgb(185, 185, 185) !important;
}
.all__right {
  margin-bottom: 10px;
  margin-right: 20px;
}
.all__right button {
  width: 130px;
  height: 40px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}
.all__right button:hover {
  background-color: #652ee5;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.4);
  color: #fff;
  transform: scale(1.1);
}

.all__right input {
  width: 300px;
  height: 40px;
  line-height: 40px;
  background: #172630;
  border: 1px solid #405266;
  padding: 0 15px;
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  color: #abb7c4;
  font-weight: 300;
  text-transform: none;
  margin-bottom: 15px;
  margin-top: 20px;
  border-radius: 3px;
}
@media (max-width: 890px) {
  .navbar {
    display: flex !important;
    justify-content: space-between !important;
    padding-left: 20px !important;
  }
  .navbar ul {
    display: none !important;
  }
  .navbar form {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  .all__right {
    display: flex !important;
    flex: 1;
    justify-content: flex-end !important;
    margin-bottom: 0 !important;
  }
  .MainHeading img {
    margin-bottom: 0 !important;
  }
  .all__right button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .all__right input {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .all__right input {
    width: 100% !important;
  }
  .navbar {
    display: flex !important;
    justify-content: center !important;
  }
  .navbar-collapse {
    display: none !important;
  }
  .all__right {
    margin-right: 0px !important;
  }
  .all__right button {
    width: 95px;
    height: 35px;
    font-size: 9px;
    margin-right: 15px;
  }
}
@media screen and (max-width: 375px) {
  .navbar {
    padding-left: 16px !important;
    padding-right: 5px !important;
  }
  .all__right button {
    width: 75px;
    height: 30px;
    font-size: 9px;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 350px) {
  .navbar {
    padding-left: 10px !important;
    padding-right: 5px !important;
  }
  .MainHeading {
    padding-left: 5px !important;
  }
  .MainHeading img {
    margin-bottom: 0 !important;
  }
  .myaccount {
    padding: 0 !important;
  }
  .login {
    padding: 0 !important;
    padding-left: 10px !important;
  }
}
