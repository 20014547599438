.footer {
  background: #131313;
  background-image: url("../../images/footer-bg.jpg");
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  position: relative;
  margin: auto;
  padding: 40px 0;
}
.footer::after {
  content: "";
  position: absolute;
  background: rgba(18, 11, 68, 0.4);
  height: inherit;
  width: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.footer__container__row {
  display: flex !important;
  justify-content: space-around;
  align-items: center !important;
  flex-wrap: wrap;
  padding: 30px 30px !important;
}
.footer .MainHeading img {
  width: 140px;
  height: 80px;
  z-index: 100;
  margin-bottom: 0px !important;
}
.footer__details {
  margin-bottom: 30px;
}
.footer__details p {
  font-size: 16px;
  color: #abb7c4;

  margin-bottom: 5px !important;
}
li {
  list-style: none;
}
.footer a {
  color: #abb7c4 !important;
  text-decoration: none;
  font-family: helvetica neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 13px;
}
.footer-email p {
  color: #abb7c4 !important;
}
.footer-title {
  z-index: 1000 !important;
  font-size: 17px;
  color: #fff !important;
}
.col-fot1,
.col-fot5 {
  flex-basis: 30%;
  min-width: 300px;
  height: 200px;
  z-index: 1000;
}
.col-fot2,
.col-fot3,
.col-fot4 {
  flex-basis: 10%;
  min-width: 250px;
  height: 200px;
  z-index: 1000;
}
.col-fot2,
.col-fot4,
.col-fot5 {
  padding-top: 30px;
}
.footer-email {
  color: #fff;
}
.footer input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: none;
  border: 1px solid #405266;
  padding: 0 15px;
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  color: #abb7c4;
  font-weight: 300;
  text-transform: none;
  margin-bottom: 15px;
  margin-top: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.footer-email-submit {
  background: transparent;
  color: red;
  text-transform: uppercase;
  border: none;
}
@media screen and (max-width: 370px) {
  .col-fot1,
  .col-fot5 {
    padding-left: 30px;
  }
}
