.search {
  height: 55px !important;
  width: 40%;
  position: relative;
  padding-right: 30px;
}
.search input {
  width: 100%;
  height: 46px;
  border-radius: 5px;
  outline: none;
  text-indent: 50px;
  border: none;
  font-weight: 400;
  padding-right: 10px;
  color: rgb(255, 255, 255);
  background-color: hsla(0, 100%, 100%, 0.16);
  box-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 11px 15px -7px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
  font-size: 17px;
  z-index: 1000;
}
.search input:focus {
  background: rgba(237, 225, 241, 0.9);
  color: #000000;
  box-shadow: 0px 24px 38px 3px rgba(24, 24, 24, 0.14),
    0px 9px 46px 8px rgba(104, 104, 104, 0.12),
    0px 11px 15px -7px rgba(88, 88, 88, 0.2);
  transform: scale(1.01);
}
.form_search {
  position: relative;
}
.icon {
  position: absolute;
  color: rgb(99, 99, 99);
  left: 15px;
  top: 27%;
}

.search Button {
  background: linear-gradient(45deg, #00aeff, #a68eff);
  height: inherit;
  position: absolute;
  color: #0d0c35;
  bottom: 0px;
  right: 0px;
}
TextField {
  width: 500px !important;
}
.search__icon {
  background: linear-gradient(45deg, #00aeff, #a68eff);
  height: 46px;
  width: 100px;
  position: absolute;
  padding-top: 8px;
  font-weight: 600;
  color: #0d0c35;
  bottom: 0px;
  right: -2px;
}

@media screen and (max-width: 880px) {
  .search {
    width: 80%;
  }
}
@media screen and (max-width: 560px) {
  .search {
    width: 90%;
    padding-right: 0;
  }
  .search__icon {
    background: linear-gradient(45deg, #00aeff, #a68eff);
    height: 46px;
    width: 70px;
    position: absolute;
    color: #0d0c35;
    bottom: 0px;
    right: -2px;
  }
}
