.SingleDataMedia {
  display: flex;
  flex-direction: column;
  width: 165px;
  margin: 15px 3px;
  position: relative;
  cursor: pointer;
  object-fit: contain;

  transition: transform 0.5s ease;

  /* background: linear-gradient(rgb(0, 0, 0, 0.4), rgb(14, 13, 13, 0.4)); */
}
.SingleDataMedia:hover {
  transform: scale(1.1);
}
.SingleDataMedia img {
  max-width: 100%;
  object-fit: contain;
  border-radius: 3px;
}
.SingleDataDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  position: absolute;
  background-color: rgba(15, 6, 54, 0.1);
  height: 45px;
  width: 100%;
  bottom: 0;
  filter: drop-shadow(0 2px 2px rgba(63, 63, 63, 0.3));
}
.SingleDataDetails h6 {
  font-size: 14px;
  bottom: 0px;
  left: 0;
  font-weight: 400 !important;
  z-index: 77;
  color: #ffffff;
  line-height: 12px;
  right: 0;
}
.vote__tag {
  font-size: 12px;
  background-color: #180e3d;
  justify-content: flex-end;
  width: 28px;
  height: 20px;
  padding: 0.2px 0;
  border-radius: 2px;
  position: absolute;
  right: 0;
}
.read__more {
  position: absolute;
  left: 35%;
  top: 35%;
  z-index: 1000;
  visibility: hidden;
}
.play__btn {
  transition: transform 0.5s ease;
}
.play__btn:hover {
  color: "red" !important;
  transform: scale(0.94);
  border: 2px solid #d13131 !important;
}
.SingleDataMedia::after {
  content: "";
  position: absolute;
  background: transparent;

  height: inherit;
  width: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s ease;
}
.SingleDataMedia:hover.SingleDataMedia::after {
  content: "";
  position: absolute;
  background: rgba(11, 5, 65, 0.5);
  height: inherit;
  width: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.SingleDataMedia:hover .read__more {
  visibility: visible;
}
.read__more button {
  background: rgba(0, 0, 0, 0.253);
  border-radius: 15px;
  border: 2px solid #000000;
  height: 35px;
  width: 75px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  color: #fff;
}
.tag {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.tag.green {
  color: #34cc34;
}
.tag.orange {
  color: orange;
}
.tag.red {
  color: red;
}
@media screen and (max-width: 1024px) {
  .SingleDataMedia {
    display: flex;
    flex-direction: column;
    width: 110px;
    margin: 15px 5px;
    position: relative;
    cursor: pointer;
    object-fit: contain;

    /* background: linear-gradient(rgb(0, 0, 0, 0.4), rgb(14, 13, 13, 0.4)); */
  }
  .SingleDataDetails h6 {
    font-size: 11px;
    bottom: 0px;
    left: 0;
    z-index: 77;

    right: 0;
  }
}
@media screen and (max-width: 820px) {
  .SingleDataMedia {
    width: 110px;
    margin: 15px 2px;
  }
}
@media screen and (max-width: 768px) {
  .SingleDataMedia {
    width: 80px;
    margin: 15px 5px;
  }
  .play__btn {
    border: 2px solid #abb7c4;
    font-size: 2.2rem !important;
  }
}
@media screen and (max-width: 550px) {
  .SingleDataMedia {
    width: 80px;
    margin: 15px 5px;
  }
}
@media screen and (max-width: 425px) {
  .SingleDataMedia {
    width: 125px;
    margin: 15px 2px;
  }
}
@media screen and (max-width: 375px) {
  .SingleDataMedia {
    width: 165px;
    margin: 15px 5px;
  }
}
@media screen and (max-width: 370px) {
  .SingleDataMedia {
    width: 155px;
    margin: 15px 5px;
  }
}
@media screen and (max-width: 350px) {
  .SingleDataMedia {
    width: 145px;
    margin: 15px 5px;
  }
}
@media screen and (max-width: 330px) {
  .SingleDataMedia {
    width: 135px;
    margin: 15px 5px;
  }
}
